import getProductsSpotlightedTemplateService
  from '~/src/components/products/products-spotlighted/styles/GetProductsSpotlightedTemplateService'
import StylesProductsSpotlightedServiceInterface
  from '~/src/components/products/products-spotlighted/styles/StylesProductsSpotlightedServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'

const useGetProductsSpotlightedTemplate = (): StylesProductsSpotlightedServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  return getProductsSpotlightedTemplateService(firmTheme?.components?.spotlightedProductsList, muiTheme)
}

export default useGetProductsSpotlightedTemplate
